const mapElement = document.querySelector(".footer__map");
const latitude = "49.4779";
const longitude = "-2.5901";

mapboxgl.accessToken =
  "pk.eyJ1IjoicXBpZGRpZ2l0YWwiLCJhIjoiY20zYnF6bXBtMGM4ODJpczZydHk5NjU3YyJ9.ia-YbWnMxOslNuOSKIa5Jg";
const map = new mapboxgl.Map({
  container: mapElement,
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 14,
});

const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#f7a800"/><path d="M31.69,21.03h-1.68v-3.55h1.68v3.55ZM26.83,21.03h-1.68v-3.55h1.68v3.55ZM22.89,21.61h-3.44v-4.12h3.44v4.12ZM22.55,16.27h-2.85v-2.77s.6-1.21,1.38-1.21,1.47,1.21,1.47,1.21v2.77ZM17.08,21.03h-1.68v-3.55h1.68v3.55ZM12.88,21.03h-1.68v-3.55h1.68v3.55ZM37,30.11v-3.79h-1.42v-5.16h-.69v-4.57h-.39l-3.32-2.43v-2.14h-.9l-1.49,2.11h-1.2l-6.46-6.46-6.46,6.46h-.73l-1.49-2.11h-.9v2.59l-2.68,1.97h-.38v4.57h-.95v5.16h-2.55v3.79h14.33v-2.31h3.34v2.31h14.33Z" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
